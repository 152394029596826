





















































@import '@design';
@import '@styleMixins';

.contact-map {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.right-pane--card {
  min-height: 100%;
  padding: 10% 7%;

  @media (min-width: 600px) {
    padding: 12% 15%;
  }
  .right-pane--title {
    @extend .motor--font;

    margin-bottom: 0.5em !important;
    line-height: 1.1 !important;
    text-transform: uppercase;

    @media (min-width: 600px) {
      padding-right: 10%;
    }
  }
  .location-summary .detail {
    line-height: 1 !important;
    white-space: pre-line;
    a {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
